<template>
	<div id="root">
        <div class="fixed-background"></div>
        <main>
            <div class="container">
                <div class="row h-100">
					<div class="col col-xxs-12 col-md-6 mx-auto my-auto text-center">
						<div class="card auth-card" no-body>
							<div class="form-side">
								<router-link tag="a" to="/">
									<img class="logo logo-full-width pb-4" width="124" src="/frontend/assets/img/klm.png" />
								</router-link>
								<h3 class="mb-4">{{ $t('pages.error-title')}}</h3>
								<p class="mb-0 text-muted text-small mb-0">{{ $t('pages.error-code')}}</p>
								<p class="display-1 font-weight-bold mb-5">404</p>
								<button type="submit" class="btn btn-primary btn-lg btn-shadow" @click="goBack">{{ $t('pages.go-back')}}</button>
							</div>
						</div>
					</div>
				</div>
            </div>
        </main>
    </div>
</template>
<script>
export default {
	methods: {
		goBack () {
			this.$router.go(-1);
		}
	},
	mounted () {
		document.body.classList.add('background')
	},
	beforeUnmount () {
		document.body.classList.remove('background')
	}
}
</script>
